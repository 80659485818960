import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { DATATABLE_TYPE } from 'state-domains/constants';

import {
    buildConfigurationHeadersBaseUrl,
    buildLoadSampleDispatchHeadersUrl,
} from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';
import { ConfigurationHeaderResponse } from '../types';

export const addConfigurationHeader = (
    payload: any,
    configType: DATATABLE_TYPE,
): Observable<ConfigurationHeaderResponse> => {
    const url =
        configType === DATATABLE_TYPE.HEADERS
            ? buildConfigurationHeadersBaseUrl()
            : buildLoadSampleDispatchHeadersUrl();
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<ConfigurationHeaderResponse>) => {
            const addHeaderResponse = convertToCamel<ConfigurationHeaderResponse>(response);
            return observableOf(addHeaderResponse);
        }),
    );
};
